<!-- ==========================-->
		<!-- SEARCH MODAL-->
		<!-- ==========================-->
		<div class="header-search open-search">
			<div class="container">
				<div class="row">
					<div class="col-sm-8 offset-sm-2 col-10 offset-1">
						<div class="navbar-search">
							<form class="search-global">
								<input class="search-global__input" type="text" placeholder="Type to search" autocomplete="off" name="s" value="" />
								<button class="search-global__btn"><i class="icon stroke icon-Search"></i></button>
								<div class="search-global__note">Begin typing your search above and press return to search.</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<button class="search-close close" type="button"><i class="fa fa-times"></i></button>
		</div>

    <!-- ==========================-->
		<!-- MOBILE MENU-->
		<!-- ==========================-->
		<!-- <div data-off-canvas="mobile-slidebar left overlay" style="{{mostrar}}"> -->
		<div data-off-canvas="mobile-slidebar left overlay" style="z-index: 9999999;">

			<button class="menu-mobile-button js-toggle-mobile-slidebar toggle-menu-button" id="menu01" ><i class="toggle-menu-button-icon"><span></span><span></span><span></span><span></span><span></span><span></span></i></button>
			<br>
			<br>
			<ul class="navbar-nav">
				<li class="nav-item active"><a class="nav-link" (click)="irhome()">Inicio</a></li>
                <!-- <li class="nav-item "><a class="nav-link" href="about.html">Sobre Unimar</a></li> -->
                <!-- <li class="nav-item "><a class="nav-link" href="listing.html">Tienda</a></li> -->
				<li><a [routerLink]="['/nosotros']">Nosotros</a></li>

        <li class="nav-item ">
          <a class="nav-link btn btn-primary" style="color:#fff;">
            CONTÁCTANOS
          </a>
        </li>
				<!-- <li><a [routerLink]="['/compra_bote']">Venta de Botes</a></li> -->
				<!-- <li class="dropdown1"> 
					<a class=" nav-link dropdown-toggle" ><span (click)="refrescar('grady_white')">Marcas</span></a>
					<div class="dropdown-content abrir"> 
						<ul>
							<a class="dropdown-item" [routerLink]="['/marcas','grady_white']" (click)="refrescar('grady_white')">GRADY WHITE</a> 
							<a class="dropdown-item" [routerLink]="['/marcas','regulator']" (click)="refrescar('regulator')">REGULATOR</a>
							<a class="dropdown-item" [routerLink]="['/marcas','robalo']" (click)="refrescar('robalo')">ROBALO</a>
							<a class="dropdown-item" [routerLink]="['/marcas','maritimo']" (click)="refrescar('maritimo')">MARITIMO</a>
							<a class="dropdown-item" [routerLink]="['/marcas','shaefer']" (click)="refrescar('shaefer')">SCHAEFER</a>
							
						</ul>
					</div>
				</li> -->

				<!-- <li class="dropdown1"> 
					<a class=" nav-link dropdown-toggle" ><span>Social</span></a>
					<div class="dropdown-content abrir"> 
						<ul>
							<a class="dropdown-item" href="https://www.facebook.com/globalmarinesv">Facebook</a> 
							<a class="dropdown-item" href="https://www.instagram.com/globalmarinesv/">Instagram</a>
							
						</ul>
					</div>
				</li> -->
				<!-- <li class="nav-item"><a>Noticias</a></li>
				<li class="nav-item"><a>Contacto</a></li> -->
				
			</ul>
		</div>

    <header class="header header-slider">
			<div class="top-bar">
				<div class="container">
					<div class="row justify-content-between align-items-center">
						<div class="col-auto">
							<!-- <div class="top-bar__item"><i class="fas fa-phone-square"></i> Phone: 755 302 8549 </div> -->
							<div class="top-bar__item" style="color: white;"><i class="fas fa-envelope-square"></i> Email: info@unimar.boats</div>
						</div>
						<div class="col-auto">
							<ul class="header-soc list-unstyled">
								<!-- <li class="header-soc__item"><a class="header-soc__link" href="https://wa.me/+50378865706" target="_blank"><i class="ic fab fa-whatsapp"></i></a></li>
								<li class="header-soc__item"><a class="header-soc__link" href="https://twitter.com/GradyLife" target="_blank"><i class="ic fab fa-twitter"></i></a></li>
								<li class="header-soc__item"><a class="header-soc__link" href="https://www.facebook.com/globalmarinepty/" target="_blank"><i class="ic fab fa-facebook-f"></i></a></li> -->
								<li class="header-soc__item"><a class="header-soc__link" href="https://www.instagram.com/unimar_sv/" target="_blank"><i class="ic fab fa-instagram"></i></a></li>
								<li class="header-soc__item"><a class="header-soc__link" href="https://www.youtube.com/@BertramYachtsUSA" target="_blank"><i class="ic fab fa-youtube"></i></a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="header-main">
				<div class="container">
					<div class="row align-items-center justify-content-between">
						<div class="col-auto">
							<a class="navbar-brand navbar-brand_light scroll" href="#"> <img class="normal-logo img-fluid" src="../assets/logounimar/logofullw.png" alt="logo" (click)="irhome()" width="120" /> </a>
							<a class="navbar-brand navbar-brand_dark scroll" href="#"><img class="normal-logo img-fluid" src="../assets/logounimar/logofullb.png" alt="logo" (click)="irhome()" width="120"/></a>
						</div>
						<div class="col-auto d-xl-none">
							<!-- Mobile Trigger Start-->
							<button class="menu-mobile-button js-toggle-mobile-slidebar toggle-menu-button" id="menu01" ><i class="toggle-menu-button-icon"><span></span><span></span><span></span><span></span><span></span><span></span></i></button>
							<!-- Mobile Trigger End-->
						</div>
						<div class="col-xl d-none d-xl-block">
							<nav class="navbar navbar-expand-lg justify-content-end" id="nav">
								<ul class="yamm main-menu navbar-nav" align="center">
									<li class="nav-item active"><a class="nav-link" href="index.html">Inicio</a> </li>
                <li class="nav-item "><a class="nav-link" [routerLink]="['/nosotros']">NOSOTROS</a> </li>
									
                                    
                <li class="nav-item ">
									<a class="nav-link btn btn-primary" style="color:#fff;">
                    CONTÁCTANOS
                  </a>
								</li>
                                   
								<!-- <li class="nav-item " onclick="cambiarDiv();">
									<a class="nav-link" [routerLink]="['']">SOCIAL</a>
								</li> -->

								</ul> 
							</nav>
						</div>
					</div>
				</div>
			</div>
		</header>
		<!-- end .header-->

 
		<!-- end .b-main-slider-->
<router-outlet></router-outlet>


<!-- ***FOOTER***
 -->

 <!-- **** -->
 <section class="section-form" style="padding: 0px">
    <div class="container">
        <div class="row">
            <div class="col-md-12"><br><br></div>
            <div class="col-md-12 col-lg-12">

                
               
                <div class="text-left" style="position: relative;">
                    <h2 class="ui-title">Contáctanos</h2>
                    <p class="justificado">Porque Unimar conoce la importancia de disfrutar de la propiedad de un yate, ofrecemos el servicio completo y eficaz de gestión de yates. Nuestro equipo proporciona apoyo y asesoramiento a los propietarios y sus capitanes lo que asegura que el yate se mantiene, opera y administra al mejor nivel posible. </p> <img src="assets/img/decore03.png" alt="photo">


                      <div class="sobremarca2"></div>

                      <div class="row">
                          <div class="col-md-6">
                              <span style="color:red ;">*</span>
                              <input #nombre type="text" class="form-control" placeholder="Nombre"> 
                          </div>
                          <div class="col-md-6">
                            <span style="color:red ;">*</span>
                            <input #apellido type="text" class="form-control" placeholder="Apellido"> 
                          </div>
                          <div class="col-md-12"><br></div>
                          <div class="col-md-6">
                            <span style="color:red ;">*</span>
                            <input #correo type="text" class="form-control" placeholder="Correo Electronico"> 
                          </div>
                          <div class="col-md-3">
                            <span style="color:red ;">*</span>
                            <input #area type="text" class="form-control" placeholder="Area"> 
                        </div>
                        <div class="col-md-3">
                            <span style="color:red ;">*</span>
                            <input #telefono type="text" class="form-control" placeholder="Telefono"> 
                        </div>
                        <div class="col-md-12"><br></div>
                        <div class="col-md-12">
                            <span style="color:red ;">*</span>
                            <textarea  #mensaje name="" id="" cols="10" rows="3" class="form-control" placeholder="Mensaje"></textarea>
                        </div>
                        <div class="col-md-12">
                            <br>
                            <div (click)="enviarcorreo(nombre.value, apellido.value, correo.value, area.value, telefono.value, mensaje.value)" class="btn btn-primary">ENVIAR</div>
                        </div>
                        <div class="col-md-12"><br><br><br></div>
                      </div>


<script type="text/javascript">
 var ifr = document.getElementById("JotFormIFrame-221097725100043");
 if (ifr) {
   var src = ifr.src;
   var iframeParams = [];
   if (window.location.href && window.location.href.indexOf("?") > -1) {
     iframeParams = iframeParams.concat(window.location.href.substr(window.location.href.indexOf("?") + 1).split('&'));
   }
   if (src && src.indexOf("?") > -1) {
     iframeParams = iframeParams.concat(src.substr(src.indexOf("?") + 1).split("&"));
     src = src.substr(0, src.indexOf("?"))
   }
   iframeParams.push("isIframeEmbed=1");
   ifr.src = src + "?" + iframeParams.join('&');
 }
 window.handleIFrameMessage = function(e) {
   if (typeof e.data === 'object') { return; }
   var args = e.data.split(":");
   if (args.length > 2) { iframe = document.getElementById("JotFormIFrame-" + args[(args.length - 1)]); } else { iframe = document.getElementById("JotFormIFrame"); }
   if (!iframe) { return; }
   switch (args[0]) {
     case "scrollIntoView":
       iframe.scrollIntoView();
       break;
     case "setHeight":
       iframe.style.height = args[1] + "px";
       break;
     case "collapseErrorPage":
       if (iframe.clientHeight > window.innerHeight) {
         iframe.style.height = window.innerHeight + "px";
       }
       break;
     case "reloadPage":
       window.location.reload();
       break;
     case "loadScript":
       if( !window.isPermitted(e.origin, ['jotform.com', 'jotform.pro']) ) { break; }
       var src = args[1];
       if (args.length > 3) {
           src = args[1] + ':' + args[2];
       }
       var script = document.createElement('script');
       script.src = src;
       script.type = 'text/javascript';
       document.body.appendChild(script);
       break;
     case "exitFullscreen":
       if      (window.document.exitFullscreen)        window.document.exitFullscreen();
       else if (window.document.mozCancelFullScreen)   window.document.mozCancelFullScreen();
       else if (window.document.mozCancelFullscreen)   window.document.mozCancelFullScreen();
       else if (window.document.webkitExitFullscreen)  window.document.webkitExitFullscreen();
       else if (window.document.msExitFullscreen)      window.document.msExitFullscreen();
       break;
   }
   var isJotForm = (e.origin.indexOf("jotform") > -1) ? true : false;
   if(isJotForm && "contentWindow" in iframe && "postMessage" in iframe.contentWindow) {
     var urls = {"docurl":encodeURIComponent(document.URL),"referrer":encodeURIComponent(document.referrer)};
     iframe.contentWindow.postMessage(JSON.stringify({"type":"urls","value":urls}), "*");
   }
 };
 window.isPermitted = function(originUrl, whitelisted_domains) {
   var url = document.createElement('a');
   url.href = originUrl;
   var hostname = url.hostname;
   var result = false;
   if( typeof hostname !== 'undefined' ) {
     whitelisted_domains.forEach(function(element) {
         if( hostname.slice((-1 * element.length - 1)) === '.'.concat(element) ||  hostname === element ) {
             result = true;
         }
     });
     return result;
   }
 };
 if (window.addEventListener) {
   window.addEventListener("message", handleIFrameMessage, false);
 } else if (window.attachEvent) {
   window.attachEvent("onmessage", handleIFrameMessage);
 }
 </script>

  
                </div> 
            </div>
     

            
        </div>
    </div>

    <!-- <div class="swiffy-slider slider-item-show4 slider-nav-outside slider-nav-dark slider-nav-sm slider-nav-visible slider-nav-page slider-item-snapstart slider-nav-autoplay slider-nav-autopause slider-item-ratio slider-item-ratio-contain slider-item-ratio-32x9 bg-white shadow-lg py-3 py-lg-4" data-slider-nav-autoplay-interval="2000">
        <div class="slider-container">
            <div><img src="assets/img/logos/Grady - White (2).png" alt="" (click)="refrescar('grady_white')"></div>
            <div><img src="assets/img/logos/Regulator (2).png" alt="" (click)="refrescar('regulator')" width="50"></div>
            <div><img src="assets/img/logos/Maritimo.png" alt="" (click)="refrescar('maritimo')"></div>
            <div><img src="assets/img/logos/schaefer-logo.png" alt="" (click)="refrescar('shaefer')"></div>
            <div><img src="assets/img/logos/Robalo (2).png" alt="" (click)="refrescar('robalo')"></div>
        </div>

        <button type="button" class="slider-nav" aria-label="Go left"></button>
        <button type="button" class="slider-nav slider-nav-next" aria-label="Go left"></button>

    </div> -->
</section>
 <!-- **** -->
 <div class="fondonegro"></div>
 <a href="https://api.whatsapp.com/send?phone=50378865706&text=%C2%A1Hola!%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Unimar." target="_blank">
 <img src="../assets/img/whatsapp.png" alt="" width="70" class="imagenwhatsapp">
 </a>
 <footer class="footer">


    <div class="container">
		
      <div class="row">
		<div class="col-lg-12 col-md-12">
			<br>
			<br>
		</div>
        <div class="col-md-4 ">
          <div style="height: 50px;"></div>
          <img src="../assets/logounimar/logofullw.png" class="img-fluid" alt="" width="150" >

        </div>

        <div class="col-lg-4 col-md-6">
          <section class="footer-section footer-section_link footer-section_link_about">
            <h3 class="footer-section__title"> Unimar</h3>
            <ul class="footer-list list-unstyled">
              <li><a [routerLink]="['/home']">Inicio</a></li>
              <li><a [routerLink]="['/nosotros']">Nosotros</a></li>
            <!--   <li><a href="#">Flota</a></li> -->
             <!--  <li><a href="#">Servicios</a></li> -->
              <!-- <li><a href="#">Contáctanos</a></li> -->
              <!-- <li><a [routerLink]="['/compra_bote']" (click)="comprabote()">Vende tu Bote</a></li> -->
            </ul>
          </section>
        </div>

        <div class="col-lg-4 col-md-6">
          <section class="footer-section">
            <h3 class="footer-section__title">Contacto El Salvador</h3>
            <div class="footer-contacts">
            <div class="footer-contacts__item"><i class="ic icon-location-pin"></i>Hangares y Marina Jaltepeque, La Paz, El Salvador</div>
            <div class="footer-contacts__item"><i class="ic icon-envelope"></i><a href="mailto:support@domain.com">info@unimar.boats</a></div>
      <!--       <div class="footer-contacts__item">

              <li class="header-soc__item"><a class="header-soc__link" href="https://www.instagram.com/globalmarinesv/" target="_blank"><i class="ic fab fa-instagram"></i></a></li>
              <li class="header-soc__item"><a class="header-soc__link" href="https://www.youtube.com/watch?v=7ZwAyIGungk" target="_blank"><i class="ic fab fa-youtube"></i></a></li>
            </div> -->
            </div>
            <ul class="footer-soc list-unstyled">
            
              <li class="header-soc__item"><a class="header-soc__link" href="https://www.instagram.com/unimar_sv/" target="_blank"><i class="ic fab fa-instagram"></i></a></li>
				      <li class="header-soc__item"><a class="header-soc__link" href="https://www.youtube.com/@BertramYachtsUSA" target="_blank"><i class="ic fab fa-youtube"></i></a></li>

              
            </ul>
            <a class="btn btn-white" href="#">¡Navega Ahora!</a> 
          </section>
        </div>
	
        
        <div class="col-lg-12 col-md-12">
          <section class="footer-section footer-section_link footer-section_link_about">
            <!-- <h3 class="footer-section__title">Flota</h3> -->
            <div class="row">
              <div class="col-md-6">
              <ul class="footer-list list-unstyled">
        <!--         <li><a [routerLink]="['/marcas', '.','grady_white']" (click)="refrescar('grady_white')">Grady-White</a></li>
                <li><a [routerLink]="['/marcas', '.','regulator']" (click)="refrescar('regulator')">Regulator</a></li>
                <li><a [routerLink]="['/marcas', '.','robalo']" (click)="refrescar('robalo')">Robalo</a></li>
                <li><a [routerLink]="['/marcas', '.','shaefer']" (click)="refrescar('shaefer')">Schaefer</a></li> -->
                <!-- <li><a href="#">Bertham</a></li> -->
                
              </ul>
              </div>
              <div class="col-md-6">
                <ul class="footer-list list-unstyled">
                 
                  <!-- <li><a href="#">Bertham</a></li> -->
                  <!-- <li><a [routerLink]="['/marcas', '.','maritimo']" (click)="refrescar('maritimo')">Maritimo</a></li>
                  <li><a [routerLink]="['/marcas', '.','maritimo']" (click)="refrescar('chaparral')">Chaparral</a></li>
                  <li><a [routerLink]="['/marcas', '.','maritimo']" (click)="refrescar('solace')">Solace</a></li> -->
                </ul>
                </div>
            </div>
          </section>
        </div>
        
      </div>
    </div>
    <div class="footer-copyright">
      <div class="container">
        
<!--         (c) 2023 Unimar Centroamérica. All rights reserved.
 -->
        Copyright &copy;
                                    <script>document.write(new Date().getFullYear());</script>  Unimar Centroamérica All rights reserved |
                                    PremierTec <i class="fa fa-heart" aria-hidden="true"></i> by <a
                                        href="https://premte.com" target="_blank">PremTe</a>
      
      </div>
    </div>
  </footer>



<!-- ***FIN**** -->



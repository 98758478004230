
<div class="b-main-slider slider-pro" id="main-slider" data-slider-width="100%" data-slider-height="100px" data-slider-arrows="false" data-slider-buttons="false">

<app-carrucel></app-carrucel>


    <div class="sp-slides">
        <!-- Slide 1-->
<!--         <div class="b-main-slider__slide b-main-slider__slide-1 sp-slide"><img class="sp-image" src="assets/img/grady1.jpg" alt="slider"  style=""/>
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="sp-layer" data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="500" data-show-delay="400" data-hide-delay="400">
                            <div class="b-main-slider__title-wrap">
                                <div class="b-main-slider__slogan">El Barco de tus Sueños</div>
                                <div class="b-main-slider__title">Encuentra a tu nuevo compañero de aventuras</div>
                                <div class="b-main-slider__label text-secondary">Descubre Grady-White</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Slide 2-->
       <!--  <div class="b-main-slider__slide b-main-slider__slide-1 sp-slide"><img class="sp-image" src="assets/img/regulator41-1.jpg" alt="slider" />
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="sp-layer" data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="500" data-show-delay="400" data-hide-delay="400">
                            <div class="b-main-slider__title-wrap">
                                <div class="b-main-slider__slogan">El Barco de tus Sueños</div>
                                <div class="b-main-slider__title">Encuentra a tu nuevo compañero de aventuras</div>
                                <div class="b-main-slider__label text-secondary">Descubre Regulator</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Slide 2-->
        <!-- <div class="b-main-slider__slide b-main-slider__slide-1 sp-slide"><img class="sp-image" src="assets/img/bertram51-1.jpg" alt="slider" />
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="sp-layer" data-width="100%" data-show-transition="left" data-hide-transition="left" data-show-duration="500" data-show-delay="400" data-hide-delay="400">
                            <div class="b-main-slider__title-wrap">
                                <div class="b-main-slider__slogan">El Barco de tus Sueños</div>
                                <div class="b-main-slider__title">Encuentra a tu nuevo compañero de aventuras</div>
                                <div class="b-main-slider__label text-secondary">Descubre Bertram</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>



        <div class="b-about" id="about">
			<div class="ui-decor ui-decor_down"></div>
			<div class="container">
				<div class="row" style="padding-top: 50px;">
					<div class="col-lg-5">
						<div class="text-left">
							<h2 class="ui-title justificado">Proporcionando una gran flota de barcos para una experiencia perfecta.</h2>
							<div class="ui-content justificado">
								<p>Broker de Servicios náuticos, cuenta con un buen número de embarcaciones, además de una tienda de accesorios y repuestos náuticos, así como taller para la reparación y mantenimiento. La empresa cuenta en la actualidad con una experimentada plantilla de profesionales que trabajan por y para la industria náutica...
									</p>
								<ul class="arrow-list">
									<li><i class="fas fa-long-arrow-alt-right"></i>Barcos y Yates Premium</li>
									<li><i class="fas fa-long-arrow-alt-right"></i>Nuestro enfoque profesional</li>
									<li><i class="fas fa-long-arrow-alt-right"></i>Servicio de Calidad Garantizado</li>
								</ul>
								<div class="gap25"></div> </div>
						</div>
					</div>

					<div class="col-lg-7">




                    <!-- partial:index.partial.html -->
                    <div class="row">
                        <div class="col-md-12">

                            <div class="swiffy-slider slider-item-ratio slider-item-ratio-4x3  slider-nav-autoplay" bis_skin_checked="1">
                                <ul class="slider-container">
                                    <li>
                                        <img src="../../assets/logounimar/unimarwebpage/marina1.jpeg" alt="">
                                    </li>
                                    <li>
                                        <img src="../../assets/logounimar/unimarwebpage/marina2.jpeg" alt="">
                                    </li>
                                    <li>
                                        <img src="../../assets/logounimar/unimarwebpage/marina3.jpeg" alt="">
                                    </li>
                                    <li>
                                        <img src="../../assets/logounimar/unimarwebpage/marina4.jpeg" alt="">
                                    </li>
                                </ul>

                                <button type="button" class="slider-nav"></button>
                                <button type="button" class="slider-nav slider-nav-next"></button>


                            </div>

                        </div>
                        <!-- <div class="col-6 col-md-6 imgtotal">
                            <div class="row">
                            <div class="col-md-12 img1">
                                <img src="../assets/newimg/bertram39cc.jpg" alt="" class="img-fluid">
                            </div>
                            <div class="col-md-12"><br></div>
                            <div class="col-md-12 img2">
                                <img src="../assets/newimg/regulator2.jpg" alt="" class="img-fluid">
                            </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-6">
                            <div class="col-md-12">
                            <br>
                            <br>
                            <br>
                            <img src="../assets/newimg/maritimo-60.png" alt="" class="img-fluid">
                            </div>
                        </div> -->
                    </div>
                    <!-- partial -->
                        <!--
                        <img src="assets/img/maritimo-60.png" alt="photo" class="about-image" style="border-radius: 15%;"> -->
                     </div>
				</div>
			</div>
		</div>
		<!-- end .b-services-->

        <!-- end .b-services-->
        <div class="section-advantages">
			<div class="container">
				<div class="row">
					<div class="col-lg-4">
						<div class="b-advantages">
							<div class="ic flaticon-rudder-1 text-secondary"></div>
							<div class="b-advantages__main">
								<div class="b-advantages__title">Venta de botes y yates</div>
								<div class="decore01"></div>
								<div class="b-advantages__info">Ahorre tiempo, en Unimar encontrará la embarcación a su gusto, nueva o usada</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="b-advantages">
							<div class="ic flaticon-snorkel text-secondary"></div>
							<div class="b-advantages__main">
								<div class="b-advantages__title">Instalación y Mantenimiento</div>
								<div class="decore01"></div>
								<div class="b-advantages__info">Instalación de diferentes tipos de sistemas eléctricos, A/C, Bombas y demás accesorios que necesite</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="b-advantages">
							<div class="ic flaticon-sailor text-secondary"></div>
							<div class="b-advantages__main">
								<div class="b-advantages__title">Servicios Administrativos </div>
								<div class="decore01"></div>
								<div class="b-advantages__info">Contamos con expertos para realizar todos sus trámites administrativos,  trámites en la AMP o avalúos </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
<!-- ***fin**** -->

<!-- ***fin**** -->
<!-- Start Logo Carousel -->


<!-- ***aqui iva el carrucel -->


<div id="logo-carousel" class="py-5">
    <div class="container text-center">
      <h2 class="pb-3">Nuestro cátalogo de marcas</h2>
        <div style="--swiffy-slider-nav-light: black;" class="swiffy-slider slider-item-show3 slider-nav-sm slider-nav-visible slider-nav-autoplay slider-nav-autopause slider-item-ratio slider-item-ratio-contain slider-item-ratio-32x9 py-3 py-lg-4" data-slider-nav-autoplay-interval="2000">
            <div class="slider-container" style="width: 85%; margin-inline: auto;">
                <div>
                  <div class="cardWhiteLogo">
                    <img class="" src="assets/img/logos/boston-whaler.png" alt="">
                  </div>
                </div>

                <div class="cardWhiteLogo">
                  <div class="cardWhiteLogo">
                    <img class="" src="assets/img/logos/cruisers-yatchs.png" alt="">
                  </div>
                  </div>

                <div>
                  <div class="cardWhiteLogo">
                    <img class="" src="assets/img/logos/tidewater.png" alt="">
                  </div>
                </div>

                <div>
                  <div class="cardWhiteLogo ">
                    <img class="" src="assets/img/logos/bertram.png" alt="">
                  </div>
                </div>

            </div>

            <button type="button" class="slider-nav" aria-label="Go left"></button>
            <button type="button" class="slider-nav slider-nav-next" aria-label="Go left"></button>

        </div>
    </div>
</div>






<!-- **fin** -->


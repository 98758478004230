


<div class="swiffy-slider slider-item-ratio slider-item-ratio-16x9 slider-nav-animation  slider-nav-autoplay" id="swiffy-animation"  data-slider-nav-autoplay-interval="13000">
    <ul class="slider-container" id="container1">


        <li id="slide1" class="">
                
            <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/ENn_goALgJA?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->

            <video class="fullscreen-video" id="myVideo" [muted]="'muted'" autoplay="autoplay"  loop>
                <source src="../../assets/logounimar/IMG_9542.mp4"/>
                Your browser does not support the video tag.
            </video>
        
        </li>
        <li id="slide1" class=""><img src="../../assets/logounimar/marina5.jpg" alt="..." loading="lazy" class="filtro">
            <!-- *** -->
            <div class="" align="">
                <div class="row">
                    <div class="col-lg-12 espaciomovil">
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                    </div>
                    <div class="col-lg-12 espacioslider"></div>
                    <div class="col-lg-2 col-md-4"></div>
                    <div class="col-lg-7 col-md-4" align="">
             
                                <div class="b-main-slider__slogan">El Barco de tus Sueños</div>
                                <div class="b-main-slider__title" >Encuentra a tu  nuevo compañero  de aventuras</div>
                                <!-- <div class="b-main-slider__label text-secondary" (click)="refrescar('grady_white')">Descubre Grady-White</div> -->
                          
                    
                    </div>
                </div>
            </div>
    
            <!-- *** -->
            
            
            
        </li>

        <li id="slide1" class=""><img src="../../assets/logounimar/nuevaimagen.jpg" alt="..." loading="lazy" class="filtro">
            <!-- *** -->
            <div class="" align="">
                <div class="row">
                    <div class="col-lg-12 espaciomovil">
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                    </div>
                    <div class="col-lg-12 espacioslider"></div>
                    <div class="col-lg-2 col-md-4"></div>
                    <div class="col-lg-7 col-md-6" align="">
             
                                <div class="b-main-slider__slogan">El Barco de tus Sueños</div>
                                <div class="b-main-slider__title" >Encuentra a tu  nuevo compañero  de aventuras</div>
                               <!--  <div class="b-main-slider__label text-secondary" (click)="refrescar('grady_white')">Descubre Grady-White</div> -->
                          
                    
                    </div>
                </div>
            </div>
    
            <!-- *** -->
            
            
            
        </li>

           

   
    </ul>

    <button type="button" class="slider-nav" aria-label="Go to previous"></button>
    <button type="button" class="slider-nav slider-nav-next" aria-label="Go to next"></button>


</div>



        
<!-- <video width="560" height="315" src="../../assets/logounimar/IMG_9542.mp4" controls="controls" poster="image" preload="true">
   
  </video> -->


import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgModule, OnInit  } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { Routes, RouterModule } from '@angular/router';
import { CompraBoteComponent } from './compra-bote/compra-bote.component';
import { DetalleBoteComponent } from './detalle-bote/detalle-bote.component';
import { MarcasComponent } from './marcas/marcas.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { CarrucelComponent } from './carrucel/carrucel.component';

import { HttpClientModule, HttpErrorResponse, HttpHeaders } from '@angular/common/http';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CompraBoteComponent,
    DetalleBoteComponent,
    MarcasComponent,
    NosotrosComponent,
    CarrucelComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    RouterModule.forRoot([ 
      { path: '',   redirectTo: '/home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'compra_bote', component: CompraBoteComponent },
      { path: 'detalle_bote/:var2', component: DetalleBoteComponent },
      { path: 'marcas/:var2', component: MarcasComponent },
      { path: 'nosotros', component: NosotrosComponent },
      { path: 'carrucel', component: CarrucelComponent }
     ], { useHash: true })
  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule implements OnInit{
  constructor(){}

  ngOnInit(){

  }

   

 }

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Alert } from 'selenium-webdriver';
import { Location } from '@angular/common';



@Component({
  selector: 'app-carrucel',
  templateUrl: './carrucel.component.html',
  styleUrls: ['./carrucel.component.css']
})
export class CarrucelComponent implements OnInit {

  constructor(private http: HttpClient, private _location: Location) { }

 

  constructor2=[]
  arraymantenimiento = [{
    "imagen": "../../assets/newbote/canyon376.webp",
    "nombre": "Canyon 376",
    "precio": 1000,
    "make": "make 1",
    "model": "grady_white",
    "condicion": "Condicion 1",
    "kilometros": 450,
    "tipo":"Tipo 1",
    "minidescripcion": "La consola central de 37 pies Canyon 376 es el barco de pesca de agua salada",
    "url": "https://www.gradywhite.com/models/center-consoles/canyon-376/",
    "invitados": "3",
    "dormitorios": "1",
    "pies": "37",
    "cocina": "1",
    "positionId": 1
  },
  {
    "imagen": "../../assets/newbote/Express330.webp",
    "nombre": "Express 330",
    "precio": 3000,
    "make": "make 1",
    "model": "grady_white",
    "condicion": "Condicion 2",
    "kilometros": 200,
    "tipo":"Tipo 1",
    "minidescripcion": "Nombrado uno de los 25 mejores barcos de pesca de agua salada en alta mar de todos los tiempos",
    "url": "https://www.gradywhite.com/models/express-cabins/express-330/",
    "invitados": "4",
    "dormitorios": "No",
    "pies": "33",
    "cocina": "No",
    "positionId": 3
  },
  {
    "imagen": "../../assets/newbote/regulator23.webp",
    "nombre": "Regulator 23",
    "precio": 3000,
    "make": "make 1",
    "model": "regulator",
    "condicion": "Condicion 2",
    "kilometros": 200,
    "tipo":"Tipo 1",
    "minidescripcion": "Regulator 23 Ya sea que esté listo para una carrera en alta mar o un día informal más cerca de la costa,",
    "url": "https://www.regulatormarine.com/offshore/boat-models/23/",
    "invitados": "3",
    "dormitorios": "No",
    "pies": "35",
    "cocina": "No",
    "positionId": 3
  },
  {
    "imagen": "../../assets/newbote/regulator25.jpg",
    "nombre": "Regulator 25",
    "precio": 3000,
    "make": "make 1",
    "model": "regulator",
    "condicion": "Condicion 2",
    "kilometros": 200,
    "tipo":"Tipo 1",
    "minidescripcion": "Hermosas líneas y el máximo rendimiento que va más allá de lo que esperarías en un barco",
    "url": "https://www.regulatormarine.com/offshore/boat-models/25/",
    "invitados": "3",
    "dormitorios": "No",
    "pies": "34",
    "cocina": "No",
    "positionId": 3
  },
  {
    "imagen": "../../assets/newbote/r160.jpg",
    "nombre": "R160",
    "precio": 3000,
    "make": "make 1",
    "model": "robalo",
    "condicion": "Condicion 2",
    "kilometros": 200,
    "tipo":"Tipo 1",
    "minidescripcion": "Le prometimos un cambio de juego y eso es exactamente lo que ofrece el nuevo R160 ",
    "url": "https://robalo.com/Robalo-Boat-X.php?id=591&action=tab_highlights&country=500",
    "invitados": "3",
    "dormitorios": "No",
    "pies": "16",
    "cocina": "No",
    "positionId": 3
  },
  {
    "imagen": "../../assets/newbote/r180.jpg",
    "nombre": "R180",
    "precio": 3000,
    "make": "make 1",
    "model": "robalo",
    "condicion": "Condicion 2",
    "kilometros": 200,
    "tipo":"Tipo 1",
    "minidescripcion": "El Robalo R180 se diseñó especialmente pensando en la aventura.",
    "url": "https://robalo.com/Robalo-Boat-X.php?id=592&action=tab_highlights&country=500#",
    "invitados": "3",
    "dormitorios": "No",
    "pies": "18",
    "cocina": "No",
    "positionId": 3
  },
  {
    "imagen": "../../assets/newbote/m1.jpg",
    "nombre": "MARITIMO M51",
    "precio": 3000,
    "make": "make 1",
    "model": "maritimo",
    "condicion": "Condicion 2",
    "kilometros": 200,
    "tipo":"Tipo 1",
    "minidescripcion": "El Maritimo M51 combina un rendimiento impresionante y características impresionantes incorporadas en el inconfundible diseño de Maritimo",
    "url": "https://www.maritimo.com.au/model/maritimo-m51/",
    "invitados": "4",
    "dormitorios": "2",
    "pies": "16",
    "cocina": "1",
    "positionId": 3
  },
  {
    "imagen": "../../assets/newbote/m2.jpg",
    "nombre": "MARITIMO M55",
    "precio": 3000,
    "make": "make 1",
    "model": "maritimo",
    "condicion": "Condicion 2",
    "kilometros": 200,
    "tipo":"Tipo 1",
    "minidescripcion": "Con un delicado equilibrio entre potencia y juego, el nuevo Maritimo M55 2020 fusiona los últimos avances en tecnología náutica ",
    "url": "https://www.maritimo.com.au/model/maritimo-m55/",
    "invitados": "3",
    "dormitorios": "2",
    "pies": "17",
    "cocina": "1",
    "positionId": 3
  },
  
  ]


  ngOnInit(): void {

    
  


 this.constructor2.push(this.arraymantenimiento)
 setTimeout(function(){
  window.scrollTo(0, 0);
}, 600);


 /* **** */

/* **** */
  }
  abrirmenu() {
    alert("hola");
    console.log("star clicked");
   }

   refrescar(e){
 /*    window.location.href = '/marcas/'+e;
    window.location.reload(); */
    window.location.href = '#/marcas/'+e;
    setTimeout(function(){
      window.location.reload();
   }, 300);
  }
  irhome(){
    window.location.href = '#/home';
    window.location.reload();
  }









}
